<!--
  功能：个人素材库
  时间：2024年12月31日 14:46:43
  版本：v1.0
-->
<template>
    <div class="h-[calc(100vh-174px)]">
        <ad-form :filedList="filedList" v-model="formData" :formPrams="formPrams" @change="change">
        </ad-form>
         <div v-if="materialList?.length > 0" class="flex flex-wrap ">
            <MaterialList :mainMenuList="materialList">
            <template
              v-slot:default="scope"
            >
              <div class="imgWrap relative box-border p-2 cursor-pointer shadow-md rounded-lg" @click.stop="event => choose(event,scope.img.id, scope.img)">
                <img
                  v-if="scope.img.type == '1'"
                  :src="scope.img.url"
                  class="w-full h-full"
                  fit="contain"
                ></img>
                
                <div
                  class="img relative"
                  v-if="scope.img.type == '2'"
                >
                  <video
                    controls
                    class="w-full h-full rounded-lg"
                    :src="scope.img.url"
                    :poster="scope.img.thumbUrl"
                    :ref="`video${scope.img.id}`"
                    @play="handlePlay(scope.img.id)"
                  ></video>
                </div>
                <span class="absolute right-3 top-3" :class="[chooseList.indexOf(scope.img.id) >= 0 ? 'active' : '']"
                  ><i class="el-icon-success"></i
                ></span>
                <p class="text-gray-600 font-bold text-sm mt-1 text-wrap ellipsis-2">{{ scope.img.file_name }}</p>
                <!-- <div class="flex items-center justify-between text-gray-400 text-xs mt-1">
                    <p class="mr-1">{{ scope.img.play_time | gettimeFormat}}</p>
                    <p>{{ scope.img.width }}*{{ scope.img.high }}</p>
                    <is-platform-right-or-wrong :platformString="scope.img.platform"></is-platform-right-or-wrong>
                </div> -->
              </div>
            </template>
        </MaterialList>
            <!-- <div v-for="(item, index) in materialList" :key="index"
                class="shadow-md imgWrap flex flex-col " @click="choose(item.id, item)">
                <div class="w-full  h-56 flex justify-center items-center">
                    <img v-if="item.type === '1'" class="w-full h-full" :src="item.url" fit="contain"></img>
                    <video v-if="item.type === '2'" class="w-full h-56" controls :src="item.url" :ref="`video${item.id}`" @play="handlePlay(item.id)"></video>
                </div>
                <span :class="[chooseList.indexOf(item.id) >= 0 ? 'active' : '']"><i class="el-icon-success"></i></span>
                <div class="w-full flex justify-between items-center gray text-sm h-5 px-5">
                    <div v-if="item.type === '2' && item.play_time" class="">{{ item.play_time }}</div>
                    <div v-if="item.width && item.high" class="">{{ item.width }}*{{ item.high }}</div>
                    <isPlatformRightOrWrong v-if="item.platform" :platformString='item.platform'></isPlatformRightOrWrong>
                </div>
                <div class="text-gray-600 font-bold text-sm mt-1 text-wrap ellipsis-1">{{ item.file_name }}</div>
            </div> -->
        </div>
        <el-empty
            v-else
            description="暂无数据"
          >
            <el-button  icon="el-icon-refresh-right" circle @click="getList"></el-button>
          </el-empty>
    </div>
</template>

<script>
import { queryGkLanguage, userSearch } from '@/api/common/basic';
import { userFileRelateList } from '@adCommonComponent/serve/material';
import {MaterialList, adForm ,isPlatformRightOrWrong} from '@adCommonComponent';
export default {
    // 组件名称
    name: 'PersonalMaterial',
    // 组件参数 接收来自父组件的数据
    props: {
        //是否打开弹窗 true打开 false关闭
        isOpen: {
            type: String,
            default: false,
        },
        // 商品ID 默认第一步填写商品
        saveAdFileParam: {
            type: Object,
            default: null,
        },
        uploadTypes: {
            type: String,
            default: 'img',
        },
        chooseList: {
            type: Array,
            default: () => [],
        },
        pageObj: {
            type: Object,
            default: () => {
                return {
                    page: 0,
                    pageSize: 10,
                };
            },
        }
    },
    // 局部注册的组件
    components: { adForm, MaterialList ,isPlatformRightOrWrong},
    // 组件状态值
    data() {
        return {
            formData: {
                pro_id: this.saveAdFileParam?.proId || '',
                lang_id: this.saveAdFileParam?.langId   || '',
                // platform: 'facebook',
                sort_type: 1,
                upload_uid: '',
                media_type: '',
            },
            filedList: [
                {
                    type: 'select',
                    key: 'sort_type',
                    placeholder: '请选择排序方式',
                    options: [
                        { name: '最新添加', id: 1 },
                        { name: '最早添加', id: 2 },
                    ],
                },
                {
                    type: 'input',
                    key: 'pro_id',
                    placeholder: '请输入产品ID/产品名称',
                    maxlength: 200,
                    config: {
                        clearable: true,
                        'show-word-limit': true,
                        
                    }
                },
                {
                    type: 'select',
                    key: 'lang_id',
                    placeholder: '请选择语种',
                    options: [],
                    change: 'lang_id',
                    refresh: true,
                    'refresh-method': this.getLanguages,
                    loading: false,
                },
                // {
                //     type: 'select',
                //     key: 'platform',
                //     placeholder: '请选择适用平台',
                //     options: [
                //         { name: 'facebook', id: 'facebook' },
                //         { name: 'tiktok', id: 'tiktok' },
                //     ],
                // },
                {
                    type: 'select',
                    key: 'upload_uid',
                    placeholder: '请选择上传人',
                    loading: false,
                    options: [],
                    selectOptions: {
                        filterable: true,
                        remote: true,
                        'remote-method': this.getUserSearch,
                    }
                },
                // {
                //     type: 'input',
                //     key: 'upload_uid',
                //     clearable: true,
                //     placeholder: '请选择上传人',
                //     change: 'upload_uid',
                // },
            ],
            formPrams: {
                inline: true,
            },
            materialList: [],
            saleLoading: false,
        };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
        // isOpen: {
        //     handler(val) {
        //         console.log(val, 'isOpen');
        //         if (val === 'SELF_MATERIAL') {
        //             this.getList();
        //             this.getLanguages();
        //             this.getUserSearch('')
        //         } else {
        //             //   this.close();
        //         }
        //     },
        //     immediate: true,
        // },
        uploadTypes: {
            handler(val) {
                console.log(val, 'uploadTypes');
                
                if (val == 'img' || val == 'banner') {
                    this.formData.media_type = '1';
                } else if (val == 'video') {
                    this.formData.media_type = '2';
                }
            },
            immediate: true,
            deep: true,
        },
    },
    // 组件方法
    methods: {
        handlePlay(id) {
            this.materialList.forEach((video) => {
                if (video.id !== id) {
                this.$refs[`video${video.id}`]?.pause();
                }
            });
        },
        choose(event,id, img) {
            event.preventDefault();
            // img.id = img.file_id;
            this.$emit('choose', id, img);
        },
        change(type, v) {
            console.log(type, v);
            if (type === 'upload_uid') {
                this.getUserSearch(v);
                return;
            }
            this.$set(this.formData, type, v);
            this.getList();
        },
        async getList() {
            const params = this.filterObject(this.formData)
            this.$showLoading();
            userFileRelateList({...params, page: this.pageObj.page, size: this.pageObj.pageSize})
                .then((res) => {
                    this.materialList = res.data.data;
                    this.$emit('changeTotal', res.data.count)
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
        filterObject(obj) {
            let filteredObj = {};
            for (let key in obj) {
                if (obj[key] !== null && obj[key] !== "") {
                    filteredObj[key] = obj[key];
                }
            }
            return filteredObj;
        },
        getLanguages() {
            const option = this.filedList.find((item) => {
                if (item.key === 'lang_id') {
                    return item;
                }

            })
            option.loading = true;
            queryGkLanguage().then((res) => {
                option.options = res.data;
            }).finally(() => {
                option.loading = false;
            })
        },
        getUserSearch(params) {
            let uidOption = this.filedList.find((item) => {
                if (item.key === 'upload_uid') {
                    return item;
                }
            })
            uidOption.loading = true;
            userSearch({ name: params }).then((res) => {
                uidOption.options = res.data;
            }).finally(() => {
                uidOption.loading = false;
            })
        },
        close() {
            this.formData = {
                pro_id: '',
                lang_id: '',
                // platform: 'facebook',
                sort_type: 1,
                upload_uid: '',
                media_type: '',
            };
            this.$destroy();
        }
    },
    /**
     * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
     */
    async created() {
        this.getLanguages();
        this.getUserSearch('')
        await this.getList();
     },
    /**
     * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
     * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
     */
    mounted() { },
    /**
     * 实例销毁之前调用。在这一步，实例仍然完全可用。
     */
    beforeDestroy() { },
    /**
     * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
     * 所有的事件监听器会被移除，所有的子实例也会被销毁。
     */
    destroyed() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<!-- <style scoped lang="scss">
.imgWrap {
    width: calc(100% / 6 - 10px);
    position: relative;
    box-sizing: border-box;
    padding-bottom: 10px;
    
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-top: 20px;
    margin-right: 10px;

    &:hover {
        span {
            i {
                opacity: 0.6;
            }
        }
    }

    img {
        // max-width: 100%;
        // max-height: 100%;
        object-fit: contain;
        border-radius: 10px;
        cursor: pointer;
    }

    // .el-image {
    //   width: 100%;
    //   height: 100%;
    //   border-radius: 10px;
    // }

    video {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        cursor: pointer;
    }
    .gray{
        color: #999;
    }
    span {
        position: absolute;
        top: 10px;
        right: 10px;

        &.active {
            i {
                color: #409eff;
                opacity: 1;
            }
        }

        i {
            font-size: 24px;
            color: #999;
            opacity: 0;
        }
    }
}
</style> -->
<style scoped lang="scss">
.imgWrap {
        background-color: #f5f5f5;

        &:hover {
          span {
            i {
              opacity: 0.6;
            }
          }
        }

        video {
          min-height: 233px;
        }

        span {
             position: absolute;
            right: 12px;
            top:12px;
          &.active {
            i {
              color: #409eff;
              opacity: 1;
            }
          }

          i {
            font-size: 24px;
            color: #999;
            opacity: 0;
           
          }
        }
      }
</style>