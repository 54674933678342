<!--
  功能：广告账户素材
  时间：2024年12月20日 16:08:45
  版本：v1.0
-->
<template>
  <div>
    <div class="headTop">
      <div class="rightHead">
        <div class="searchInput">
          <el-input
            placeholder="输入关键词搜索"
            size="mini"
            v-model="formInline.keyword"
          >
            <el-button
              type="text"
              slot="append"
              @click="getList"
              ><i class="el-icon-search"></i
            ></el-button>
          </el-input>
        </div>
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link color-theme">
            <i class="el-icon-sort"></i>
            <span>{{formInline.sortDirection == 'ASC' ? '时间升序' : '时间倒序' }}</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item command="">默认排序</el-dropdown-item> -->
            <el-dropdown-item command="DESC">时间倒序</el-dropdown-item>
            <el-dropdown-item command="ASC">时间升序</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button
          class="sort"
          type="text"
        >
        </el-button>
        <el-button
          class="filter"
          type="text"
          @click="filterShow = !filterShow"
        >
          <i class="el-icon-cold-drink"></i>
          <span>筛选</span>
        </el-button>
      </div>
    </div>
    <div class="listCon">
      <div class="list">
        <div class="listWrap">
          <image-water-full
            v-if="imgList.length > 0"
            :mainMenuList="imgList"
          >
            <template
              v-slot:default="scope"
            >
              <div class="imgWrap shadow-md" @click="event => choose(event,scope.img.id, scope.img)">
                <img
                  v-if="scope.img.mediaType == 'IMAGE'"
                  :src="scope.img.url"
                  fit="contain"
                ></img>

                <div
                  class="img"
                  v-if="scope.img.mediaType == 'VIDEO'"
                >
                  <video
                    controls
                    :src="scope.img.url"
                    :poster="scope.img.thumbUrl"
                    @play="savePlay(scope.img.id)"
                    :ref="`video${scope.img.id}`"
                  ></video>
                </div>
                <span :class="[chooseList.indexOf(scope.img.id) >= 0 ? 'active' : '']"
                  ><i class="el-icon-success"></i
                ></span>
              </div>
            </template>
          </image-water-full>
          
          <el-empty
            v-else
            description="暂无数据"
          >
            <el-button  icon="el-icon-refresh-right" circle @click="getList"></el-button>
          </el-empty>
        </div>
      </div>
      <div
        class="filterCon"
        v-if="filterShow"
      >
        <div class="filterHeader">
          <span>筛选条件</span>
          <div>
            <el-button
              type="text"
              @click="clear"
              >清空</el-button
            >
            <i
              class="el-icon-circle-close close"
              @click="closeFilter"
            ></i>
          </div>
        </div>
        <div class="filterMsg">
          <el-collapse v-model="activeNames">
            <el-collapse-item
              title="颜色"
              name="1"
            >
              <el-color-picker
                v-model="formInline.color"
                @change="getList"
              ></el-color-picker>
            </el-collapse-item>
            <el-collapse-item
              title="形状"
              name="2"
            >
              <div style="margin-top: 20px">
                <el-radio-group
                  v-model="formInline.sharpe"
                  size="medium"
                  @change="getList"
                >
                  <el-radio-button
                    label="width"
                    value="width"
                    >宽</el-radio-button
                  >
                  <el-radio-button
                    label="high"
                    value="high"
                    >高</el-radio-button
                  >
                  <el-radio-button
                    label="square"
                    value="square"
                    >方</el-radio-button
                  >
                </el-radio-group>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="上传时间"
              name="3"
              style="text-align: center"
            >
              <el-date-picker
                v-model="formInline.timeStart"
                type="date"
                placeholder="选择开始时间"
                style="margin-bottom: 15px"
                @change="getList"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="formInline.timeEnd"
                type="date"
                placeholder="选择结束时间"
                @change="getList"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { search, accounts } from '@/api/creatAd.js';
import { mapGetters } from 'vuex';
import ImageWaterFull from '@/components/common/ImageWaterFull.vue';
export default {
  // 组件名称
  name: 'AdAccountMaterial',
  // 组件参数 接收来自父组件的数据
  props: {
    //是否打开弹窗 true打开 false关闭
    isOpen: {
      type: String,
      default: '',
    },
    uploadTypes: {
      type: String,
      default: 'img',
    },
    chooseList: {
      type: Array,
      default: () => [],
    },
    pageObj: {
      type: Object,
      default: () => {
        return {
          page: 0,
          pageSize: 10,
        };
      },
    }
  },
  // 局部注册的组件
  components: { ImageWaterFull },
  // 组件状态值
  data() {
    return {
      activeNames: '1',
      filterShow: false,
      imgList: [],
      formInline: {
        sourceFroms: ['UPLOAD', 'FBADACCOUNT', 'WORKFLOW'],
        mediaType: 'IMAGE',
        keyword: null,
        sharpe: null,
        color: '',
        sortDirection: 'DESC', //排序方式
      },
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(['accountId']),
  },
  // 侦听器
  watch: {
    uploadTypes: {
      handler(val) {
          if (this.uploadTypes == 'img' || this.uploadTypes == 'banner') {
            this.formInline.mediaType = 'IMAGE';
          } else if (this.uploadTypes == 'video') {
            this.formInline.mediaType = 'VIDEO';
          }
      },
      immediate: true,
      deep: true,
    }
  },
  // 组件方法
  methods: {
    getList() {
      this.$showLoading();
      delete this.formInline.sourceFroms;
      let request = JSON.parse(JSON.stringify(this.formInline));
      request.timeStart = request.timeStart ? request.timeStart + ' 00:00:00' : '';
      request.timeEnd = request.timeEnd ? request.timeEnd + ' 00:00:00' : '';
      this.imgList = [];
      search({ ...request, page: this.pageObj.page, pageSize: this.pageObj.pageSize, accountId: this.accountId }).then((res) => {
        this.imgList = res.data.content;
        this.$emit('changeTotal', res.data.totalElements)
        // this.total = res.data.totalElements;
      }).finally(() => {
        setTimeout(() => {
          this.$hideLoading();
        }, 500);
      })
    },
    choose(event,id, img) {
      event.preventDefault();
      this.$emit('choose', id, img);
    },
    closeFilter() {
      this.filterShow = false;
      this.clear();
      this.getList();
    },
    clear() {
      this.formInline.sharpe = null;
      this.formInline.startTime = null;
      this.formInline.endTime = null;
      this.formInline.color = '';
    },
    close() {
      this.savePlay('');
      this.formInline = {
        sourceFroms: ['UPLOAD', 'FBADACCOUNT'],
        mediaType: 'IMAGE',
        keyword: null,
        sharpe: null,
        color: '',
        sortDirection: 'DESC',
      };
      this.clear();
    },
    // 排序方式
    handleCommand(v) {
      this.$set(this.formInline, 'sortDirection', v);
      this.getList();
    },
    savePlay(curId) {
      this.imgList.forEach((item) => {
        if (item.id != curId) {
          this.$refs[`video${item.id}`].pause();
        }
      });
    },
  },
  /**
   * 组件实例创建完成，属性已绑定，但DOM还未生成，$ el属性还不存在
   */
  created() {
    console.log(this.uploadTypes);
    this.getList();
  },
  /**
   * el 被新创建的 vm.$ el 替换，并挂载到实例上去之后调用该钩子。
   * 如果 root 实例挂载了一个文档内元素，当 mounted 被调用时 vm.$ el 也在文档内。
   */
  mounted() {
    console.log(this.uploadTypes);
  },
  /**
   * 实例销毁之前调用。在这一步，实例仍然完全可用。
   */
  beforeDestroy() {console.log(this.uploadTypes);},
  /**
   * Vue 实例销毁后调用。调用后，Vue 实例指示的所有东西都会解绑定，
   * 所有的事件监听器会被移除，所有的子实例也会被销毁。
   */
  destroyed() {console.log(this.uploadTypes);},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--使用了scoped属性之后，父组件的style样式将不会渗透到子组件中，-->
<!--然而子组件的根节点元素会同时被设置了scoped的父css样式和设置了scoped的子css样式影响，-->
<!--这么设计的目的是父组件可以对子组件根元素进行布局。-->
<style scoped lang="scss">
.listCon {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - 214px);

  .list {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    // padding: 10px;

    .listWrap {
      height: calc(100vh - 242px);
      //   overflow-y: auto;

      &::-webkit-scrollbar {
        width: 6px;
        // height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.2);
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
      // ul {
      //   display: flex;
      //   flex-wrap: wrap;

      .imgWrap {
        // width: 20%;
        // height: 200px;
        // margin: 10px;
        position: relative;
        box-sizing: border-box;
        padding: 10px;
        cursor: pointer;
        // border: 1px dashed #efefef;
        // border: 1px solid #E5E7E9;
        background-color: #f5f5f5;
        border-radius: 10px;
        // box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

        &:hover {
          span {
            i {
              opacity: 0.6;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }

        // .el-image {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 10px;
        // }

        video {
          width: 100%;
          height: 100%;
        }

        span {
          position: absolute;
          top: 10px;
          right: 10px;

          &.active {
            i {
              color: #409eff;
              opacity: 1;
            }
          }

          i {
            font-size: 24px;
            color: #999;
            opacity: 0;
          }
        }
      }
      // }
    }
  }

  .filterCon {
    flex: 242px 0 0;
    font-size: 14px;
    border-left: 1px solid #ddd;
    height: 100%;

    .filterMsg {
      padding: 10px;
    }

    .filterHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      i {
        font-size: 16px;
        color: #666;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}

.headTop {
  // padding-left: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 0;
  //   border-bottom: 1px solid #ddd;

  .rightHead {
    display: flex;
    align-items: center;
    font-size: 14px;

    .searchInput {
      display: flex;
      align-items: center;
      margin-right: 5px;

      i {
        font-size: 16px;
        margin-right: 10px;
        margin-left: 10px;
        color: #999;
        cursor: pointer;
      }
    }

    .sort {
      margin: 0 10px;
    }
  }
}
</style>
